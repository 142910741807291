<template>
  <div v-if="this.card_competencies.length > 0">
    <div style="font-size: 24px; margin-bottom: 20px">
      Компетенции
      <el-popover
        placement="top"
        width="400"
        trigger="click"
      >
        <el-button
          slot="reference"
          class="table-action-button open-desc-button"
          type="text"
          round
          size="mini"
          icon="fas fa-info-circle"
        ></el-button>
        <div style="white-space: pre-line">Компетенции - это стандарты поведения, содержащие знания и навыки, необходимые для эффективного выполнения работы и достижения результатов</div>
      </el-popover>
    </div>
    <el-table :data="card_competencies" border>
      <el-table-column width="300" label="Компетенции" v-slot="scope">
        {{ scope.row.competence.name }}
        <el-popover
          placement="top"
          width="400"
          trigger="click"
        >
          <el-button
            slot="reference"
            class="table-action-button open-desc-button"
            type="text"
            round
            size="mini"
            icon="fas fa-info-circle"
          ></el-button>
          <div style="white-space: pre-line">{{ scope.row.competence.description }}</div>
        </el-popover>
      </el-table-column>
      <el-table-column  min-width="300"  label="Поведенческие проявления" v-slot="scope">
        <div v-if="card.user.category === 'руководители'" v-html="scope.row.competence.indicators_manager"></div>
        <div v-else v-html="scope.row.competence.indicators_student"></div>
      </el-table-column>
      <el-table-column
        width="240"
        v-if="isSeeColumn(user.id === card.user_id)"
        label="Комментарий Сотрудника"
        v-slot="scope"
      >
        <competence-comment-table-column
          entity-type="card_competence"
          :scope="scope"
          stage="employee"
          :last-comment="scope.row.last_comment_by_stage['employee']"
          :comments-count="scope.row.comments_count_by_stage['employee']"
        />
      </el-table-column>
      <el-table-column
        width="230"
        v-if="isSeeColumn()"
        label="Оценка Руководителя"
        v-slot="scope"
      >
        <el-select
          style="width: 180px !important;"
          :disabled="!isEvaluateCompetence"
          v-model="scope.row.evaluation_id"
          placeholder="Выберите оценку"
          @change="evaluateCompetence(scope.row)"
        >
          <el-option
            v-for="competency_evaluation in competence_evaluations"
            :key="competency_evaluation.id"
            :label="competency_evaluation.name"
            :value="competency_evaluation.id"
          >
          </el-option>
        </el-select>
        <el-popover
          placement="top"
          width="400"
          trigger="click"
        >
          <el-button
            slot="reference"
            class="table-action-button open-desc-button"
            type="text"
            round
            size="mini"
            icon="fas fa-info-circle"
          ></el-button>
          <div style="white-space: pre-line">{{ getDescription(scope.row.evaluation_id) }}</div>
        </el-popover>
      </el-table-column>
      <el-table-column
        width="240"
        v-if="isSeeColumn()"
        label="Комментарий Руководителя"
        v-slot="scope"
      >
        <competence-comment-table-column
          entity-type="card_competence"
          :scope="scope"
          stage="chief"
          :last-comment="scope.row.last_comment_by_stage['chief']"
          :comments-count="scope.row.comments_count_by_stage['chief']"
        />
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import requestSender from "@/api/base/requestSender";
import {mapGetters} from "vuex";
import CompetenceCommentTableColumn
  from "@/componentsCompany/smz/tableColumnTemplates/card/CompetenceCommentTableColumn.vue";

export default {
  name: "card-competencies",
  components: {CompetenceCommentTableColumn},
  computed: {
    ...mapGetters('card', {
      card: 'card'
    }),
    ...mapGetters(['user']),
    ...mapGetters('card/stages', {
      currentStage: 'currentStage',
      currentNegotiation: 'currentNegotiation',
    }),
    isEvaluateCompetence() {
      return this.$canAndRulesPass(this.card, 'competencies.evaluate_update')
    },
  },
  props: {
    cardId: {type: Number, default: null},
  },
  data() {
    return {
      card_competencies: [],
      competence_evaluations: [],
    }
  },
  mounted() {
    if (this.cardId) {
      this.getCardCompetencies();
    }
  },
  methods: {
    isSeeColumn(onlyStage = false) {
      if (!onlyStage && this.user.id === this.card.user_id) {
        return this.card.info.total_evaluations_visibility;
      } else {
        return (this.currentStage && this.currentStage.type === this.$constants.stage.types.TYPE_ASSESSMENT)
            || (this.card.status === this.$constants.card.statuses.STATUS_COMPLETED)
      }
    },
    getCardCompetencies() {
      requestSender('get', 'card-competence/get-card-competencies', {
        expand: [
          'last_comment_by_stage',
          'comments_count_by_stage',
        ].join(),
        card_id: this.cardId,
      })
        .then(({card_competencies, competence_evaluations}) => {
          this.card_competencies = card_competencies;
          this.competence_evaluations = competence_evaluations;
        })
    },
    evaluateCompetence(row) {
      requestSender('post', 'card-competence/evaluate-competence', {
        card_id: this.card.id,
        card_competence_id: row.id,
        evaluation_id: row.evaluation_id,
      })
        .then(() => {
          this.$notify.success({
            title: 'Оценено',
            message: 'Оценка успешно выставлена',
          });
        })
    },
    getDescription(evaluation_id) {
      const evaluation = this.competence_evaluations.find(e => e.id === evaluation_id);
      return evaluation ? evaluation.description : 'Оценка не выбрана';
    },
  }
}
</script>

<style scoped></style>